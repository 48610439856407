import React from 'react';
import Intro from 'src/components/_shared/intro/intro';
import SEO from 'src/components/_shared/seo';

const Index = () => (
  <>
    <SEO title="Варшавська битва 1920 рік" lang="ua" description="Iсторія найважливішого бою, про який ви, ймовірно, ніколи не чули." />
    <Intro locale="ua" />
  </>
);
export default Index;
